import React from 'react';
import { Section, Paragraph } from '../components';
import { useTranslation } from '../hooks';

export function AboutUs(props) {
  const title = useTranslation('aboutUs.title');
  const paragraphs = useTranslation('aboutUs.paragraphs');

  return (
    <Section {...props} title={title}>
      {paragraphs.map((value, idx) => (
        <Paragraph
          key={idx}
          dangerouslySetInnerHTML={{__html: value}}
        />
      ))}
    </Section>
  );
}
