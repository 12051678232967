import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../hooks';
import { Paragraph, Map, Section } from '../components';

export function Location(props) {
  const title = useTranslation('location.title');
  const postal = useTranslation('contactUs.postal');
  const paragraphs = useTranslation('location.paragraphs');

  return (
    <Section {...props} title={title}>
      {paragraphs.map((value, idx) => (
        <Paragraph
          key={idx}
          dangerouslySetInnerHTML={{__html: value}}
        />
      ))}

      <AddressBox>
        <h4>{postal.title}</h4>
        {postal.address}
      </AddressBox>

      <Map
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3&key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}`}
        containerElement={<div style={s.mapContainer} />}
        loadingElement={<div style={s.mapElement} />}
        mapElement={<div style={s.mapElement} />}
        position={{lat: 43.485806, lng: 16.111038}}
        zoom={10}
      />
    </Section>
  );
}

const AddressBox = styled.div`
  margin-top: 80px;
  margin-bottom: 25px;
  & > h4 {
    margin-bottom: 10px;
  }
`;

const s = {
  mapContainer: {
    width: '100%',
    height: '480px',
    marginBottom: '50px',
  },
  mapElement: {
    height: '100%',
    borderRadius: '5px',
  },
}
