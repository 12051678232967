import React, { useCallback } from 'react';
import { navigate } from 'gatsby';
import { Button, Box } from 'grommet';
import { useGallery, useTranslation } from '../hooks';
import { Gallery, Section } from '../components';

export function Pictures(props) {
  const pictures = useGallery();
  const title = useTranslation('lang.pictures');
  const seeMore = useTranslation('pictures.seeMore');

  const navToGallery = useCallback(async () => {
    await navigate('/gallery');
  }, []);

  return (
    <Section {...props} wide title={title}>
      <Gallery
        items={pictures}
      />

      <Box direction="row" justify="center" margin={{top: 'large'}}>
        <Button
          primary
          size="large"
          label={seeMore}
          onClick={navToGallery}
        />
      </Box>
    </Section>
  );
}
