import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../hooks';
import { Horizontal, Paragraph, Section } from '../components';
import * as Icons from '../resources/icons';

export function Amenities(props) {
  const title = useTranslation('amenities.title')
  const groups = useTranslation('amenities.groups');
  const paragraphs = useTranslation('amenities.paragraphs');

  return (
    <Section {...props} gray title={title}>
      <Horizontal style={{justifyContent: 'space-around'}}>
        {groups.map(props => (
          <AmenitiesGroup
            key={props.title}
            {...props}
          />
        ))}
      </Horizontal>

      {paragraphs.map((value, idx) => (
        <Paragraph
          key={idx}
          dangerouslySetInnerHTML={{__html: value}}
        />
      ))}
    </Section>
  );
}

const AmenitiesGroup = (props) => (
  <AmenitiesContainer>
    <img
      src={Icons[props.iconName]}
      alt={props.iconName}
      width={40}
    />

    <AmenitiesTitle>
      {props.title}
    </AmenitiesTitle>

    <ul style={{alignSelf: 'flex-start'}}>
      {props.items.map(value => (
        <AmenitiesItem key={value}>
          {value}
        </AmenitiesItem>
      ))}
    </ul>
  </AmenitiesContainer>
);

const AmenitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2em;
  width: 20vw;
  min-width: 250px;
  @media (min-width: 1200px) {
    min-width: 250px;
    width: 12vw;
  }
`;

const AmenitiesTitle = styled.h3`
  margin-bottom: 1.1rem;
`;

const AmenitiesItem = styled.li`
  line-height: 1.5rem;
  list-style: none;
  &:before {
    content: "\\2713\\0020";
    margin-right: 10px;
  }
`;
