import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../hooks';
import { Horizontal, Section } from '../components/layout';

export function Info(props) {
  const title = useTranslation('info.title')
  const sections = useTranslation('info.sections');

  return (
    <Section {...props} title={title}>

      <Horizontal>
        {sections.map(props => (
          <InfoSection
            key={props.title}
            {...props}
          />
        ))}
      </Horizontal>
    </Section>
  );
}

const InfoSection = (props) => (
  <InfoContainer>
    <InfoTitle>
      {props.title}
    </InfoTitle>

    <ul>
      {props.items.map(value => (
        <InfoItem key={value}>
          {value}
        </InfoItem>
      ))}
    </ul>
  </InfoContainer>
);

const InfoContainer = styled.div`
  margin: 2em;
`;

const InfoTitle = styled.h2`
  text-align: center;
  margin-bottom: 1.1em;
`;

const InfoItem = styled.li`
  font-size: 1.1em;
  line-height: 1.5em;
`;
