import React from 'react';
import styled from 'styled-components';
import { Container, Grid, Header, Icon, List } from 'semantic-ui-react';
import { useTranslation } from '../hooks';
import { Section } from '../components';

export function ContactUs(props) {
  const title = useTranslation('contactUs.title');
  const postal = useTranslation('contactUs.postal');
  const email = useTranslation('contactUs.email');
  const call = useTranslation('contactUs.call');
  const social = useTranslation('contactUs.social');

  /*(
  <Group
    title={call.title}
    icon="call"
    content={call.tel}
    href={`tel:${call.tel.split(' ').join('')}`}
  />
  )*/

  return (
    <Section {...props} gray title={title}>
      <StyledContainer textAlign='center'>
        <Grid columns={4} divided stackable>
          <Grid.Row>
            <Group
              title={postal.title}
              icon="map marker alternate"
              content={postal.address}
              href="https://g.page/antini-dvori-vinisce?share"
            />

            <Group
              title={call.title}
              icon="whatsapp"
              content={call.tel}
            />

            <Group
              title={email.title}
              icon="mail"
              content={email.address}
              href={`mailto:${email.address}?subject=${email.subject}`}
            />

            <Group
              title={social.title}
              icon="instagram"
              content={social.username}
              href={social.url}
            />
          </Grid.Row>
        </Grid>
      </StyledContainer>
    </Section>
  );
}

const Group = ({title, icon, content, href}) => (
  <Grid.Column>
    <Icon
      name={icon}
      size="big"
      color="teal"
    />

    <Header
      as='h3'
      content={title}
      style={s.header}
    />

    <List link>
      {Array.isArray(content) ? (
        content.map(s => <div>{s}</div>)
      ) : (
        <List.Item
          as={href ? 'a' : 'span'}
          href={href}
          target={href && href.startsWith('http') ? '_blank' : undefined}
          content={content}
          style={s.item}
        />
      )}
    </List>
  </Grid.Column>
);

const StyledContainer = styled(Container)`
  margin-top: 15vh;
  margin-bottom: 20vh;
`;

const s = {
  header: {
    marginBottom: '25px',
  },
  item: {
    fontSize: '1.2em',
  },
};
