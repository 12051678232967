import React from 'react';
import styled from 'styled-components';
import { Hero } from '../resources/images';
import { Underline } from '../components/text';
import { useTranslation } from '../hooks';

const Background = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 55px);
  background-image: url(${Hero});
  background-position: 50% 80%;
  background-size: cover;
`;

const OpacityFilter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.25;
`;

const Heading = styled.div`
  position: relative;
  z-index: 5;
  color: white;
  margin-top: -100px;
  margin-left: -10vw;
  @media(max-width: 667px) {
    margin: -20px 40px 0px 40px;
  }
`;

const Headline = styled.h1`
  font-size: 3.4vw;
  @media(max-width: 667px) {
    font-size: 1.8rem;
  }
`;

const Tagline = styled.h2`
  margin-left: 2px;
  font-weight: normal;
  font-style: italic;
  font-size: 1.9vw;
  @media(max-width: 900px) {
    font-size: 1.3rem;
  }
`;

export function Welcome(props) {
  return (
    <Background {...props}>
      <OpacityFilter />

      <Heading>
        <Underline as={Headline}>
          {useTranslation('welcome.headline')}
        </Underline>

        <Tagline>
          {useTranslation('welcome.tagline')}
        </Tagline>
      </Heading>
    </Background>
  );
}
