import React from 'react';
import { Horizontal, Section } from '../components/layout';
import { useTranslation } from '../hooks';
import { PriceCard } from '../components/media';

export function Price(props) {
  return null; // disable for now

  const title = useTranslation('price.title');
  const tiers = useTranslation('price.tiers');

  return (
    <Section {...props} gray wide title={title}>
      <Horizontal>
        {tiers.map(tier => (
          <PriceCard
            key={tier.name}
            tier={tier.name}
            price={tier.price}
            extra={tier.conditions}
          />
        ))}
      </Horizontal>
    </Section>
  );
}
