import '../i18n';
import React from 'react';
import { Box } from 'grommet';
import { useTranslation } from '../hooks';
import { Welcome, AboutUs, Accommodation, Price, Location, ContactUs, Amenities, Pictures } from '../ui';
import { Page, FAB, Theme } from '../components';

export default function HomePage() {
  const callToAction = useTranslation('callToAction');

  return (
    <Page>
      <Box style={{marginTop: Theme.navBarHeight}}>
        <Welcome id="welcome" />
      </Box>
      <AboutUs id="about-us" />
      <Accommodation id="accommodation" />
      <Pictures id="pictures" />
      <Amenities id="amenities" />
      <Location id="location" />
      <Price id="price" />
      <ContactUs id="contact-us"/>

      <FAB
        label={callToAction}
        icon="mail"
        href="#contact-us"
      />
    </Page>
  );
}
