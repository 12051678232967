import React from 'react';
import { useTranslation } from '../hooks';
import { Section, Paragraph } from '../components';

export function Accommodation(props) {
  const title = useTranslation('accommodation.title')
  const paragraphs = useTranslation('accommodation.paragraphs');

  return (
    <Section {...props} gray title={title}>
      {paragraphs.map((value, idx) => (
        <Paragraph
          key={idx}
          dangerouslySetInnerHTML={{__html: value}}
        />
      ))}
    </Section>
  );
}
